<template>
  <div class="main-header" :style="{    backgroundImage:`url(${require(isAxa?'../../../assets/axa-title.png':'../../../assets/header-bg.png')})` }">
    <div class="time">
      <a-icon class="icon" type="dashboard" />
      <div class="center">
        <span class="time1">{{time1}}</span>
        <span class="time2">{{time2}}</span>
      </div>
      <img @click="visible=true" src="../../../assets/avator.png" alt="">
      
    </div>
    <div class="breadmenu">
      <span>您所在的位置：</span>
      <span v-for="(item,index) in menuList" class="menu-item" :key="index">{{item}} <span class="line" v-if="index!==menuList.length-1">/</span></span>
    </div>

    <ul-modal2 v-model:visible="visible" title="提示" @ok="handleLogout">
      <p>确认要退出吗?</p>
    </ul-modal2>
  </div>
</template>

<script>
import moment from 'moment'
import store from 'store'
export default {
  name: "main-header",
  data(){
    return {
      time1:'',
      time2:'',
      visible:false,
      isAxa:''
    }
  },

  mounted() {
    const tenantId=store.get('tenantId');
    this.isAxa=tenantId=='1,1572084238034636802';
    this.time1 = moment(new Date().getTime()).format('HH:mm:ss')
    this.time2 = moment(new Date().getTime()).format('yyyy-MM:DD')
    setInterval(()=>{
      this.time1 = moment(new Date().getTime()).format('HH:mm:ss')
      this.time2 = moment(new Date().getTime()).format('yyyy-MM:DD')
    },1000)
  },
  computed:{
    menuList(){
      return this.$route.meta
    },
  },
  methods:{
    handleLogout(){
      store.remove('token');
      this.$router.push({
        name:'login'
      })
    },
    handleCancel(){
      this.visible=false;
    },
  },
}
</script>

<style scoped lang="less">
.main-header{
    width: 100%;
    height: 14%;
    background-size: 100% 100%;
    position: relative;
  .time{
    position: absolute;
    display: flex;
    padding-left: 40px;
    align-items: center;
    right: 0;
    top:40%;
    width: 231px;
    height: 68px;
    background: url("../../../assets/time-bg.png");
    background-size: 100% 100%;
    .icon{
      margin-right: 20px;
    }
    img{
      width: 40px;
      height: 40px;
      border-radius: 100%;
    }
    .center{
      display: flex;
      flex-direction: column;
      margin-right: 20px;
      .time1{
        font-size: 16px;
      }
      .time2{
        font-size: 12px;
      }
    }
  }
  .breadmenu{
    position: absolute;
    bottom: 10px;
    left:30px;
    .line{
      display: inline-block;
      margin: 0 10px;
    }
  }
}
</style>