<template>
  <div class="menu" style="width: 256px">
    <a-menu
        mode="inline"
        theme="dark"
        :defaultSelectedKeys="defaultOpenKeys"
        :open-keys.sync="defaultOpen"
        @select="handlerSelect"
        :inline-collapsed="collapsed"
    >
      <a-sub-menu v-for="(item,index) in routes" :key="item.name">
        <span slot="title" class="title-box">
          <!-- <img  class="icon" :src="computedIconByTitle(item.title)" /> -->
          <a-icon :type="item.icon" />
          <span>{{ item.title }}</span></span>
        <a-menu-item v-for="(ite,inde) in item.children" :key="ite.name">
          {{ite.title}}
        </a-menu-item>
      </a-sub-menu>
    </a-menu>
  </div>
</template>

<script>
import {menuRouter} from "@/router";
import  car from '@/assets/icons/car.png';
import  record from '@/assets/icons/record.png';
import  setting from '@/assets/icons/setting.png';
import  user from '@/assets/icons/user.png';
import {apiList} from "@/utils/system-api";
import store from 'store'
import Config from "@/utils/config";
export default {
  name: "leftMenu",
  computed:{
    computedIconByTitle(title){
      return function (title){
        switch (title) {
          case '人员管理':
            return user;
          case '预约管理':
            return record;
          case  '车辆调度':
            return car;
          case '系统设置':
            return setting;
        }
      }
    }
  },
  data() {
    return {
      collapsed: false,
      routes:menuRouter,
      defaultOpenKeys:[],
      defaultOpen:[],
    };
  },
  mounted(){
      this.renderRouter()

  },
  methods: {
    renderRouter(){
      // apiList();
      let this_routes=JSON.parse(JSON.stringify(menuRouter));
      let menuList=(store.get('menuList')||[]);
        let name = ''
        this_routes.forEach(x=>{
          if(x.children){
            //和menuList结合
            x.children=x.children.map(e=>{
              let theSameNameObj=menuList.find(e2=>e2.code==e.name);
              if(theSameNameObj){
                e={...e,
                title:theSameNameObj.name||e.title,
                sort:theSameNameObj.sort,
                displayMode:theSameNameObj.displayMode==1
                };
              }else if(Config.isSHowAllPage){
                e.displayMode=true;
              }else{
                e.displayMode=false;
              }
              return e;}).filter(e=>e.displayMode);
            x.children=x.children.sort((e1,e2) =>e1.sort*1-e2.sort*1)
            // x.children=x.children.filter(e=>!e.isHide).filter(e=> {
            //   return (isGuard && e.guard) || !isGuard
            // })
            x.children.forEach(y=>{
              if(y.name == this.$route.name)
                name = x.name
            })
          }
        })
        this.defaultOpen = [name]
        this.routes=this_routes.filter(e=>!e.isLink).filter(e=>!e.isHide).filter(e=>e.children.length)
        this.defaultOpenKeys.push(this.$route.name)
    },
    handlerSelect(item,key){
      if(item.key=='dataV'){
        window.open(`./#/dataV2/index`)
        return false;
      }
      this.$router.push({name:item.key})
    },
  },
}
</script>

<style scoped lang="less">
.menu{
  padding: 20px;
  border-right: 1px solid #0C4064;
  height: 100%;
  overflow-y: auto;
}
.menu/deep/.ant-menu-submenu-title{
  background: linear-gradient(270deg, rgba(3,88,168,0) 0%, #0358A8 100%);
}.menu/deep/.ant-menu-inline.ant-menu-sub{
  background: transparent;
   color: #87D0E0;
}.menu/deep/.ant-menu-dark{
  background: transparent;
    color: #87D0E0;
}.menu/deep/.ant-menu-item{
  background: transparent;
    color: #87D0E0;
}
.menu/deep/.ant-menu-item-selected{
  background: rgba(0,62,102,0.21)!important;
  box-shadow: inset 5px 5px 14px 0px rgba(0,179,232,0.5), inset -5px -5px 14px 0px rgba(0,179,232,0.5), inset 2px 2px 3px 0px rgba(0,179,232,0.5), inset -2px -2px 3px 0px rgba(0,179,232,0.5)!important;;
  border: 1px solid #00B3E8 !important;;
}
.icon{
  height:14px;
  width:14px;
}
.title-box{
  display: flex;
  align-items: center;
}
</style>