import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import Main from "@/views/Main/Main";
import store from 'store';
import {message} from "ant-design-vue";
Vue.use(VueRouter)
export const menuRouter = [
  {
    path: '/',
    name: 'main',
    component: Main,
    title:'人员管理',
    icon:'team',
    children:[
      {
        path: '/home/index',
        name: 'homeIndex',
        title:'长期访客管理',
        component: () => import('@/views/personnel/permanent'),
        meta: ['首页','人员管理','长期访客管理'],
        guard:true
      },
      {
        path: '/home/tempperson',
        name: 'tempIndex',
        title:'临时访客管理',
        component: () => import('@/views/personnel/temp'),
        meta: ['首页','人员管理','临时访客管理'],
        guard:true
      },
      {
        path: '/system/workman',
        name: 'workman',
        title:'员工管理',
        component: () => import('@/views/system/user'),
        meta: ['首页','人员管理','员工管理']
      },
      // {
      //   path: '/home/record',
      //   name: 'personRecord',
      //   title:'预约记录查询',
      //   component: () => import('@/views/personnel/record'),
      //   meta: ['首页','人员管理','预约记录查询']
      // },

      {
        path: '/home/permanent/record',
        name: 'permanentRecord',
        title:'长期访客日志',
        component: () => import('@/views/personnel/permanent/record'),
        meta: ['首页','人员管理','长期访客日志'],
        guard:true
      },
      {
        path: '/home/record2',
        name: 'personRecord2',
        title:'预约记录查询',
        component: () => import('@/views/personnel/record2'),
        meta: ['首页','人员管理','预约记录查询'],
        guard:true
      },
    ]
  },
  {
    path: "/booking",
    name: "booking",
    component: Main,
    title:'预约管理',
    icon:'form',
    children: [
      {
        path: '/visitor',
        name: 'visitor',
        title:'我的访客',
        // component: () => import('@/views/booking/visitor'),//这是一个静态页面
        component: () => import('@/views/booking/approve'),
        meta: ['首页','预约管理','我的访客']
      },
      {
        path: '/approve',
        name: 'approve',
        title:'我的审批',
        component: () => import('@/views/booking/approve'),
        meta: ['首页','预约管理','我的审批']
      },
      {
        path: '/approve-list',
        name: 'approve-list',
        title:'审批列表',
        component: () => import('@/views/booking/approve'),
        meta: ['首页','预约管理','审批列表']
      },
      {
        path: '/apply',
        name: 'apply',
        title:'预约申请',
        component: () => import('@/views/booking/apply'),
        meta: ['首页','预约管理','预约申请']
      },

    ]
  },
  {
    path: "/truck",
    name: "truck",
    redirect: '/truck/index',
    component: Main,
    title: "车辆调度",
    icon:"car",
    children: [
      // {
      //   path: '/truck/index',
      //   name: 'truckLog',
      //   title:'车辆日志',
      //   component: () => import('@/views/truck/log'),
      //   meta: ['首页','车辆调度','车辆日志']
      // },
      {
        path: '/truck/dispatcher',
        name: 'dispatcher',
        title:'园区调度',
        component: () => import('@/views/truck/dispatcher'),
        meta: ['首页','车辆调度','车辆调度']
      },
      {
        path: '/truck/reserve',
        name: 'reserve',
        title:'车辆进出管理V1',
        component: () => import('@/views/truck/reserve'),
        meta: ['首页','车辆调度','车辆进出管理V1']
      },
      {
        path: '/dataV/index',
        name: 'dataV',
        title:'统计大屏',
        meta: ['首页','车辆调度','统计大屏'],
        guard:true
      },
      {
        path: '/truck/vehicle',
        name: 'vehicle',
        title:'预约申请记录',
        meta: ['首页','车辆调度','预约申请记录'],
        component: () => import('@/views/truck/vehicle'),
      },
      {
        path: '/truck/verify',
        name: 'verify',
        title:'特殊车辆审核',
        meta: ['首页','车辆调度','特殊车辆审核'],
        component: () => import('@/views/truck/verify'),
      },
    ]
  },
  {
    path: "/system",
    name: "system",
    redirect: '/system/index',
    component: Main,
    icon:'setting',
    title: "系统设置",
    children: [
      {
        path: '/system/dept',
        name: 'dept',
        title:'部门管理',
        component: () => import('@/views/system/dept'),
        meta: ['首页','系统设置','部门管理']
      },
      {
        path: '/system/grade',
        name: 'grade',
        title:'职位管理',
        component: () => import('@/views/system/grade'),
        meta: ['首页','系统设置','职位管理']
      },
      {
        path: '/system/role',
        name: 'role',
        title:'角色管理',
        component: () => import('@/views/system/role'),
        meta: ['首页','系统设置','角色管理']
      },
      {
        path: '/system/user',
        name: 'user',
        title:'用户管理',
        component: () => import('@/views/system/user'),
        meta: ['首页','系统设置','用户管理']
      },
      {
        path: '/system/menu',
        name: 'menu',
        title:'菜单管理',
        component: () => import('@/views/system/menu'),
        meta: ['首页','系统设置','菜单管理']
      },
      {
        path: '/system/msg',
        name: 'msg',
        title:'短信管理',
        component: () => import('@/views/system/msg'),
        meta: ['首页','系统设置','短信管理']
      },
    ]
  },
  {
    path: '/dataV2/index',
    name: 'dataV2',
    title:'调度台',
    component: () => import('@/views/dataV/lagreDataScreen'),
    meta: ['首页','车辆调度','调度台'],
    isLink:true
  },

  {
    path: '/login',
    name: 'login',
    title:'登录',
    component: () => import('@/views/user/Login'),
    isLink:true
  },
  {
    path: '/login4axa',
    name: 'login4axa',
    title:'奥喜埃登录',
    component: () => import('@/views/user/Login'),
    isLink:true
  },
]
export const routes = [
    ...menuRouter,
    {
      path: '/login',
      name: 'login',
      title:'登录',
      component: () => import('@/views/user/Login'),
    },
]



const router = new VueRouter({
  routes
})
// 路由判断登录 根据路由配置文件的参数
router.beforeEach((to, from, next) => {
  console.log('写这行注释是为了便于定位到路由守卫的代码', {to, from});
  if (to.matched.some(record => !record.name.includes('login'))){ // 判断该路由是否需要登录权限
    console.log('需要路由权限,进行token判断')
    if (store.get('token')) { // 判断当前的token是否存在 ； 登录存入的token
      console.log('token存在,放行路由')
      next();
    }
    else {
      console.log('token不存在,发生重定向')
      next({
        name: 'login',
        query: {redirect: to.fullpath} // 将跳转的路由path作为参数，登录成功后跳转到该路由
      })
    }
  }
  else {
    console.log("不需要路由权限")
    next();
  }
});
export default router
