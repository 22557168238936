<template>
<!--  <dv-full-screen-container>-->
  <div style="width: 100%;height: 100%">
    <mainHeader></mainHeader>
    <div class="body">
      <leftMenu></leftMenu>
      <div class="router-view">
        <!--      https://blog.csdn.net/qq_45838886/article/details/124494007-->
        <!--      解决vue中多个路由共用同一个组件时，组件不会重新触发生命周期函数的问题-->
        <router-view :key="$route.fullPath"/>
      </div>
    </div>
  </div>

<!--  </dv-full-screen-container>-->
</template>

<script>
import mainHeader from './module/main-header'
import leftMenu from "@/views/Main/module/leftMenu";
export default {
  name: "Main",
  components:{
    mainHeader,
    leftMenu
  }
}
</script>

<style scoped lang="less">
.body{
  width: calc(100% - 60px);
  height: calc(85% - 30px);
  margin: 0 30px 30px 30px;
  background: linear-gradient(135deg, rgba(0,22,56,0.5) 0%, rgba(0,22,56,0) 100%);
  box-shadow: -2px -2px 10px 0px rgba(0,0,0,0.3), inset 6px 6px 20px 0px rgba(4,137,211,0.4), inset -6px -6px 20px 0px rgba(4,137,211,0.5);
  border: 3px solid;
  border-image: linear-gradient(180deg, rgba(18, 103, 143, 1), rgba(4, 137, 211, 1)) 3 3;
  display: flex;
  .router-view{
    width: calc(100% - 256px);
    overflow-y:auto;
  }
}
</style>