import request from "@/utils/request";

//部门列表
export const deptList=(postData)=>{
    return request({
        url:'dept/list',
        method:'post',
        data:postData,
    })
}
//部门详情
export const deptDetail=({deptId})=>{
    return request({
        url:`dept/detail/${deptId}`,
        method:'get',
    })
}
//部门更新
export const deptUpdate=(postData)=>{
    return request({
        url:`dept/update`,
        method:'put',
        data:postData,
    })
}
//部门新增
export const deptAdd=(postData)=>{
    return request({
        url:`dept/add`,
        method:'post',
        data:postData,
    })
}
//部门删除
export const deptDelete=({deptId})=>{
    return request({
        url:`dept/delete/${deptId}`,
        method:'delete',
    })
}

//职位列表
export const postGradeList=(postData)=>{
    return request({
        url:'post-grade/list',
        method:'post',
        data:postData,
    })
}

//职位详情
export const postGradeDetail=({id})=>{
    return request({
        url:`post-grade/detail/${id}`,
        method:'get',
    })
}

//职位更新
export const postGradUpdate=(postData)=>{
    return request({
        url:`post-grade/update`,
        method:'put',
        data:postData,
    })
}

//职位新增
export const postGradeAdd=(postData)=>{
    return request({
        url:`post-grade/add`,
        method:'post',
        data:postData,
    })
}

//职位删除
export const postGradeDelete=({id})=>{
    return request({
        url:`post-grade/delete/${id}`,
        method:'delete',
    })
}
//角色列表
export const roleList=(postData)=>{
    return request({
        url:'role/list',
        method:'post',
        data:postData,
    })
}
//角色详情
export const roleDetail=({roleId})=>{
    return request({
        url:`role/detail/${roleId}`,
        method:'get',
    })
}
//角色更新
export const roleUpdate=(postData)=>{
    return request({
        url:`role/update`,
        method:'put',
        data:postData,
    })
}
//角色增加
export const roleAdd=(postData)=>{
    return request({
        url:`role/add`,
        method:'post',
        data:postData,
    })
}
//角色删除
export const roleDelete=({roleId})=>{
    return request({
        url:`role/delete/${roleId}`,
        method:'delete',
    })
}
//用户列表
export const userList=(postData)=>{
    return request({
        url:'user/list',
        method:'post',
        data:postData,
    })
}
//用户详情
export const userDetail=({userId})=>{
    return request({
        url:`user/detail/${userId}`,
        method:'get',
    })
}
//用户更新
export const userUpdate=(postData)=>{
    return request({
        url:`user/update`,
        method:'put',
        data:postData,
    })
}
//用户新增
export const userAdd=(postData)=>{
    return request({
        url:`user/add`,
        method:'post',
        data:postData,
    })
}
//用户删除
export const userDelete=({userId})=>{
    return request({
        url:`user/delete/${userId}`,
        method:'delete',
    })
}
//绑定角色
export const userBindRoles=(postData)=>{
    return request({
        url:`user/bind/roles`,
        method:'post',
        data:postData
    })
}
//绑定菜单
export const roleBindMenus=(postData)=>{
    return request({
        url:`role/bind/menus`,
        method:'post',
        data:postData
    })
}
//查询用户拥有的角色
export const queryUserRoles=({userId})=>{
    return request({
        url:`user/query/roles/${userId}`,
        method:'get',
    })
}
//查询角色拥有的菜单
export const queryRoleMenus=({roleId})=>{
    return request({
        url:`role/query/menus/${roleId}`,
        method:'get',
    })
}
//菜单列表
export const menuList=(postData)=>{
    return request({
        url:'menu/list',
        method:'post',
        data:postData,
    })
}

//菜单详情
export const menuDetail=({menuId})=>{
    return request({
        url:`menu/detail/${menuId}`,
        method:'get',
    })
}

//菜单更新
export const menuUpdate=(postData)=>{
    return request({
        url:`menu/update`,
        method:'put',
        data:postData
    })
}

//菜单新增
export const menuAdd=(postData)=>{
    return request({
        url:`menu/add`,
        method:'post',
        data:postData,
    })
}

//菜单删除
export const menuDelete=({menuId})=>{
    return request({
        url:`menu/delete/${menuId}`,
        method:'delete',
    })
}


//api列表资源
export const apiBind=({menuId})=>{
    return request({
        url:`menu/bind/api-endpoints`,
        method:'post',
    })
}

//查询菜单拥有的api资源
export const queryMenuApi=({menuId})=>{
    return request({
        url:`menu/query/menus/${menuId}`,
        method:'get',
    })
}

//api列表资源
export const apiList=()=>{
    return request({
        url:`/menu/api-endpoint/list`,
        method:'post',
        data:{
            pageNum:1,
            pageSize:99999
        }
    })
}