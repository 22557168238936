<template>
    <div class="main" >
            <slot></slot>
    </div>
  </template>
  
  <script>
  export default {
    name: "pageHeader",
    components: {},
    data() {
      return {
      }
    }
}
  </script>
  
  <style lang="less" scoped>
    .main {
        display: flex;
        flex-direction: row-reverse;
        margin-bottom: 40px;
    }
  </style>
  