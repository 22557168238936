import axios from 'axios'
import store from "store";
import { message } from 'ant-design-vue';
import config from './config';
import router from '../router/index'
// 创建 axios 实例
const request = axios.create({
    // API 请求的默认前缀
    baseURL:config.baseURL,
    // baseURL:'http://127.0.0.1:9999',
    timeout: 20000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {

    console.log(error.response)
    if (error.response) {
        const msg = error.response.data.msg
        // 从 localstorage 获取 token
        message.error(msg)
        if (error.response.status === 403) {

        }

    }
    return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
    config.headers['Content-Type']="application/json";

    const token = store.get('token')
    // const user = storage.get('User')

    // 如果 token 存在
    // 让每个请求携带自定义 token 请根据实际情况自行修改
    if (token&&config.token!=='login/get-token') {
        config.headers['authorization'] = 'Bearer '+token;
        // config.headers['user'] = user;
    }

    return config
}, errorHandler)

// response interceptor
request.interceptors.response.use(
    async (response) => {
        // return response.data
        if (response.data.code == "100450") {
            store.remove('token');
            message.warning('登录状态已失效,正在重新登录！');
            setTimeout(()=>{
                router.push({
                    name:store.get('tenantId')=='1,1572084238034636802'?'login4axa':'login',
                })
            },2000)

            // let res=await axios.post(config.baseURL+'login/refresh-token',{
            //         refreshToken:store.get('refreshToken')
            // });
            // store.set('refreshToken',res.data.data.refreshToken);
            // store.set('token',res.data.data.token);
            // return ;
        }
        else if(response.data.code=='100452'){
            message.warning(response.data.msg)
            return response.data;
        }
        else if(response.data.code!='000000' && response.data.msg){
            message.warning(response.data.msg)
            return response.data;
        }
        else {
            return response.data;
        }

    }, errorHandler)


export default request

